<template>
  <section class="conOfMissionVission h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'SSBD', part2: 'Committees' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="data.length > 0">
      <v-container>
        <v-row>
          <v-col cols="12" v-for="(item, i) in data" :key="i">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <div class="titleSec red">{{ item.header }}</div>
              </div>
              <div class="descriptionContainerSec" v-html="item.text"></div>
              <div class="subTitleContainerSec red" v-if="item.subHeader">
                <div class="subTitleSec">
                  <b>{{ item.subHeader }}</b>
                </div>
              </div>
              <div
                class="descriptionContainerSec"
                v-if="item.subText"
                v-html="item.subText"
              ></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Committees",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
    data: [
      {
        header: "1. Administrative & Finance Committee:",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b> SSBD President (Dr. Feras Alfraih)<br>
          <b style="color:#2f2a91">Member:</b> SSBD Treasurer  (Dr. Fahad AlMohareb)<br>
          <b style="color:#2f2a91">Member:</b> SSBD General Secretary (Dr. Ali AlAhmari)</p>`,
      },
      {
        header: "2. Scientific Clubs Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b> SSBD President (Dr. Feras Alfraih ) <br />
          <b style="color:#2f2a91">Vice chair:</b> SSBD Vice President (Dr. Amal Albeihany) </p>`,
        subHeader: "Sub-committees:",
        subText: `<p>
          <b style="color:#2f2a91">2.1</b>   Member Benign hematology sub-committee: (Dr. Othman Ayoub)<br />
          <b style="color:#2f2a91">2.2</b>  Member Hematological Malignancies sub-committee: (Dr. Amal Albeihany)<br />
          <b style="color:#2f2a91">2.3</b>   Member HSCT & CT sub-committee: (Dr. Ali AlAhmari) </p>`,
      },
      {
        header: "3. Research & Publications Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b> SSBD Board member  (Dr. Tarek Owaidah)<br />
          <b style="color:#2f2a91">Vice chair:</b> SSBD regular member <br />
          <b style="color:#2f2a91">Member</b> of trainees committee <br />
          <b style="color:#2f2a91">Members</b> SSBD regular member.
          </p>`,
      },
      {
        header: "4. Annual Congress Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b> SSBD board member (Dr. Amal Albeihany)<br />
          <b style="color:#2f2a91">Vice chair:</b>SSBD board member  <br />
          <b style="color:#2f2a91">SSBD regular member</b><br />
          </p>`,
      },
      {
        header: "5. Partnership and Strategy Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b>SSBD board member (Dr. Ahmed Tarawah)<br />
          <b style="color:#2f2a91">Vice chair:</b>SSBD board member<br />
          <b style="color:#2f2a91">SSBD regular member</b><br />
          </p>`,
      },
      {
        header: "6. Public Service & Education ",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b>SSBD board member  (Dr. Feras Alfraih)<br />
          <b style="color:#2f2a91">Vice chair:</b>SSBD board member (Dr. Almohammadi)<br />
          <b style="color:#2f2a91">SSBD regular member</b><br />
          </p>`,
      },
      {
        header: "7. Membership Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b>SSBD board member  (Dr. Ahmed Tarawah)<br />
          <b style="color:#2f2a91">Member of scientific committee </b><br />
          <b style="color:#2f2a91">SSBD regular member</b><br />
          </p>`,
      },
      {
        header: "8. Media Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b>SSBD board member (Dr. Feras Alfraih)<br />
          <b style="color:#2f2a91">Member of Public service committee</b><br />
          <b style="color:#2f2a91">Regular SSBD member</b><br />
          </p>`,
      },
      {
        header: "9. Trainees Committee",
        text: `<p>
          <b style="color:#2f2a91">Chair:</b>(Dr. Ashraf Warsi)<br />
          <b style="color:#2f2a91">SSBD Regular member (Adult Hematology)</b><br />
          <b style="color:#2f2a91">SSBD Regular member (Pediatric Hematology)</b><br />
          <b style="color:#2f2a91">SSBD Regular member (Hematopathology)</b><br />
          <b style="color:#2f2a91">Up to 5 Trainees</b>
          </p>`,
      },
    ],
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./_missionAndVision.scss";
</style>
